import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { hideScrollbars, mounter } from "../../utils"
import { cleanString } from "oj-utils"
import { initialize, getState, setOpen, nextLevel, isEmptyOrNullArray, prevLevel, IItem, arrayHasLength } from "./logic"
import { Flyout } from "oj-mithriljs-packages/dist/Flyout/Flyout"
import { Svg } from "../../svg"
import { mapIcon } from "../../types"

export interface IMobileNavigation { mobileNavigationItems: any, allInTranslation: string }
export interface IMobileNavigationItem { item: IItem, showIcon: boolean, allIn: string }

export class MobileNavigation extends MithrilTsxComponent<IMobileNavigation> {
    dom: Element

    oninit(v: m.Vnode<IMobileNavigation>) {
        initialize(v.attrs.mobileNavigationItems)
    }

    view(v: m.Vnode<IMobileNavigation>) {
        const { open, title, activeLevel, items } = getState()

        return [
            <button className="sub-item hamburger-trigger" aria-label="menu" onclick={() => setOpen(true)}><i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/hamburger.svg" /></i></button>,
            <Flyout
                className="MobileNavigation"
                open={open}
                onopen={open => setOpen(open)}
                ondismiss={() => hideScrollbars(false)}
                dismiss
                animate
            >
                <header className="flyout-nav-header">
                    {activeLevel > 0
                        ? <div>
                            <button type="button" onclick={prevLevel}><Svg src="/FrontendWebpack/dist/assets/newIcons/arrow-left.svg" /></button>
                        </div>
                        : <div>
                            <a href="/" className="logo"><Svg src="/FrontendWebpack/dist/assets/logo/Hema-logo.svg" /></a>
                        </div>
                    }
                    <button className="close" onclick={e => {
                        setOpen(false)
                        hideScrollbars(false)
                    }}><Svg src="/FrontendWebpack/dist/assets/newIcons/close.svg" /></button>
                </header>
                <div className="flyout-nav-body">
                    <nav role="navigation">
                        <ul className="flyout-nav-root" style={`transform: translateX(-${activeLevel * 100}%)`}>
                            {(items ?? []).map(x =>
                                <FlyoutNavItem item={x} showIcon={true} allIn={v.attrs.allInTranslation} />
                            )}
                        </ul>
                    </nav>
                </div>
            </Flyout>]
    }

    oncreate(v: m.VnodeDOM<{}>) {
        this.dom = v.dom
    }
}

class FlyoutNavItem extends MithrilTsxComponent<IMobileNavigationItem> {
    view(v: m.Vnode<IMobileNavigationItem>) {
        const { visible, active, link, items, title, icon } = v.attrs.item

        return <li className={cleanString(visible && "visible", active && "active")}>
            <div className="flyout-nav-item">
                {link
                    ? arrayHasLength(items)
                        ? <button aria-label={link.name} onclick={e => {
                            nextLevel(v.attrs.item)
                            e.target.closest("nav").scrollTop = 0

                        }}>{v.attrs.showIcon && <Svg src={mapIcon[icon]} />}<span>{link.name}</span><Svg src="/FrontendWebpack/dist/assets/newIcons/small_chevron_right.svg" /></button>
                        : <a href={link.url} target={link.target} rel={link.target === "_blank" ? "noreferrer noopener" : ""} aria-label={link.name}>{v.attrs.showIcon && <Svg src={mapIcon[icon]} />}<span>{link.name}</span></a>
                    : <span>{title}</span>
                }
            </div>

            {active && isEmptyOrNullArray(items) &&
                <ul>
                    <li className="title">
                        <span className="h2">{link.name}</span>
                    </li>
                    <li>
                        <div class="flyout-nav-item">
                            <a href={link.url} target={link.target} rel={link.target === "_blank" ? "noreferrer noopener" : ""} aria-label={link.name}>{v.attrs.allIn} {link.name}</a>
                        </div>
                    </li>
                    {items.map(x =>
                        <FlyoutNavItem item={x} showIcon={false} allIn={v.attrs.allIn} />
                    )}
                </ul>
            }
        </li>
    }
}

mounter<IMobileNavigation>("[data-m-mobile-navigation]", (r, d) => {
    m.mount(r, {
        oncreate: dom => r.replaceWith(dom.dom),
        view: v => <MobileNavigation {...d} />
    })
})