export interface IState {
    title: string
    open: boolean
    activeLevel: number
    items: IItem[],
}

export interface ILink {
    name: string
    target?: any
    url: string
}

export interface IItem {
    title?: string
    link: ILink
    items?: IItem[]
    active?: boolean
    visible?: boolean
    parent?: IItem | undefined
}

const state: IState = {
    title: "",
    open: false,
    activeLevel: 0,
    items: [],
}

const normalizeItems = (items: IItem[]) => {
    const walk = (item: IItem, parent: IItem | undefined) => {
        item.parent = parent
        if (item.items)
            item.items.forEach(x => walk(x, item))
    }

    items.forEach(x => walk(x, undefined))
    return items
}

export const setItems = (items: IItem[]) => {
    if (items.length < 1)
        return

    state.items = normalizeItems(items)
}

export const setOpen = (open = true) => {
    state.open = open
}

export const setClosed = setOpen.bind(null, false)

export const setName = (title: string) =>
    state.title = title

export const getState = () =>
    state

export const nextLevel = (item: IItem) => {
    if (item.active)
        return

    state.activeLevel++
    item.active = true
    item.visible = true
    setName(item.title)

    let p = item.parent
    while (p) {
        p.visible = false
        p = p.parent
    }
}

export const prevLevel = () => {
    const activeItem = findDeepestActive(state.items) as IItem
    state.activeLevel--
    activeItem.active = false
    activeItem.visible = false

    if (activeItem.parent)
        activeItem.parent.visible = true

    setName(activeItem.parent?.title ?? state.title)
}

export const findDeepestActive = (children: IItem[]): IItem | undefined => {
    const next = children.find(x => !!x.active)
    if (!next)
        return

    const res = findDeepestActive(next.items)
    if (!res)
        return next

    return res
}

export const arrayHasLength = (array: null | undefined | any[]) =>
    Array.isArray(array) && array.length > 0

export const isEmptyOrNullArray = (array: null | undefined | any[]) =>
    Array.isArray(array) && array.length > 0

export const initialize = () => {
    // const convert = (x: HTMLElement): IItem => {
    //     const nested = x.querySelector("ul") !== null
    //     const link = x.querySelector("a")
    //     const title = nested ? x.querySelector("span").innerText : link.innerText
    //     return {
    //         title,
    //         link: {
    //             name: title,
    //             url: link.getAttribute("href"),
    //         },
    //         items: nested ? Array.from(x.querySelector("ul").children).map(convert) : undefined,
    //     }
    // }

    // const root = document.querySelector("#mobile-nav > ul")
    // setItems(Array.from(root.children).map(convert))
    const items = window["mobileMenu"].map(x => {
        x.title === ""
    })


    setItems(window["mobileMenu"])
}