export interface IImage {
    altText: string,
    imageUrl: string,
    retinaUrl: string,
    minWidth?: string,
    width: number,
    height: number
}

export interface IOption<T = string | number> {
    value: T,
    text?: string,
    selected?: boolean
    priceDifferencePrefix?: string,
    priceDifference?: number,
    price?: number,
    isAvailable?: boolean,
    color?: string,
    min?: number,
    max?: number
}

export const mapIcon = {
    "mijn-bestellingen": "/FrontendWebpack/dist/assets/newIcons/basket.svg",
    "mijn-fotoprojecten": "/FrontendWebpack/dist/assets/newIcons/photo.svg",
    "wachtwoord-wijzigen": "/FrontendWebpack/dist/assets/newIcons/password.svg",
    "hema-pas": "/FrontendWebpack/dist/assets/newIcons/loyalty.svg",
    "mijn-gegevens": "/FrontendWebpack/dist/assets/newIcons/profile.svg",
    "uitloggen": "/FrontendWebpack/dist/assets/newIcons/sign-out.svg",
    "fotoboeken": "/FrontendWebpack/dist/assets/newIcons/photo-album.svg",
    "afdrukken": "/FrontendWebpack/dist/assets/newIcons/print.svg",
    "wanddecoratie": "/FrontendWebpack/dist/assets/newIcons/wall-decoration.svg",
    "fotocadeaus": "/FrontendWebpack/dist/assets/newIcons/basket-holiday.svg",
    "wenskaarten": "/FrontendWebpack/dist/assets/newIcons/gift-card.svg",
    "digitaliseren": "/FrontendWebpack/dist/assets/newIcons/natuurlijk-oorsprong.svg",
    "ontwikkelen": "/FrontendWebpack/dist/assets/newIcons/camera.svg",
    "foto_Snelservice": "/FrontendWebpack/dist/assets/newIcons/star.svg",
    "service": "/FrontendWebpack/dist/assets/newIcons/support.svg",
    "acties": "/FrontendWebpack/dist/assets/newIcons/discount.svg",
    "digitaal": "/FrontendWebpack/dist/assets/newIcons/natuurlijk-oorsprong.svg",
    "analoog": "/FrontendWebpack/dist/assets/newIcons/camera.svg",
}