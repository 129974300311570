import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { cleanString } from "oj-utils"
import { initialize, getState, setOpen, nextLevel, isEmptyOrNullArray, prevLevel, IItem, arrayHasLength } from "./logic"
import { Flyout } from "oj-mithriljs-packages/dist/Flyout/Flyout"

export interface IMobileNavigationItem { item: IItem }

export class MobileNavigation extends MithrilTsxComponent<{}> {
    dom: Element

    oninit(v: m.Vnode<{}>) {
        initialize()
    }

    view(v: m.Vnode<{}>) {
        const { open, title, activeLevel, items } = getState()

        return <Flyout
            className="MobileNavigation"
            open={open}
            onopen={open => setOpen(open)}
            dismiss
            animate
        >
            <header className="flyout-nav-header">
                {activeLevel > 0
                    ? <div>
                        <button type="button" onclick={prevLevel}><i className="icon-arrow-left"></i></button>
                        <h2>{title}</h2>
                    </div>
                    : <h2>menu</h2>
                }
                <button onclick={e => setOpen(false)}><i className="icon-delete-light"></i></button>
            </header>
            <div className="flyout-nav-body">
                <nav role="navigation">
                    <ul className="flyout-nav-root" style={`transform: translateX(-${activeLevel * 100}%)`}>
                        {items.map(x =>
                            <FlyoutNavItem item={x} />
                        )}
                    </ul>
                </nav>
            </div>
        </Flyout>
    }

    oncreate(v: m.VnodeDOM<{}>) {
        this.dom = v.dom
    }
}

class FlyoutNavItem extends MithrilTsxComponent<IMobileNavigationItem> {
    view(v: m.Vnode<IMobileNavigationItem>) {
        const { visible, active, link, items, title } = v.attrs.item

        return <li className={cleanString(visible && "visible", active && "active")}>
            <div className="flyout-nav-item">
                {link
                    ? arrayHasLength(items)
                        ? <button aria-label={link.name} onclick={e => {
                            nextLevel(v.attrs.item)
                            e.target.closest("nav").scrollTop = 0
                        }}>{link.name} <i className="icon-arrow-right"></i></button>
                        : <a href={link.url} target={link.target} rel={link.target === "_blank" ? "noreferrer" : ""} aria-label={link.name}>{link.name}</a>
                    : <span>{title}</span>
                }
            </div>

            {active && isEmptyOrNullArray(items) &&
                <ul>
                    <li>
                        <div class="flyout-nav-item">
                            <a href={link.url} target={link.target} rel={link.target === "_blank" ? "noreferrer" : ""} aria-label={link.name}>{link.name}</a>
                        </div>
                    </li>
                    {items.map(x =>
                        <FlyoutNavItem item={x} />
                    )}
                </ul>
            }
        </li>
    }
}

const div = document.createElement("div")
document.body.appendChild(div)
m.mount(div, MobileNavigation)

Array.from(document.querySelectorAll(".header-toggle-nav")).forEach(x => x.addEventListener("click", e => {
    setOpen()
    m.redraw()
}))